import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Skabioza",
            image: "https://i.imgur.com/Fj3Z3sub.jpg",
            description: "Arī šo ziedu “māsas” violetā krāsā bieži var atrast Latvijas pļavās. Parasti šie ziedi ir baltā, violetā vai zilganā krāsā. Interesti, ka ziedi bagāti ar nektāru, tāpēc pļavās ap tiem riņķo tauriņi.",
        },
        {
            title: "Clematis jeb mežvītenis",
            image: "https://i.imgur.com/351Ve18b.jpg",
            description: "Izrādās, ka šos augus Latvijā plašāk sāka audzēt tikai no 1960. gada un šobrīd tie ir iemīļoti vīteņaugi dārzos. Tie iedalās lielziedu un mazziedu, kopā pasaulē ir ap 250-300 dažādu sķirņu. Lielākais vairums aug Eirāzijas kontinentā.",
        },
        {
            title: "Fritilārijas",
            image: "https://i.imgur.com/wmphGamb.jpg",
            description: "Jeb kā sarunvalodā bieži saukti par zvaniņiem. Šī suga savvaļā aug Austrumturcijā un tā izceļas ar tumši sarkanbrūno zvana krāsu, kam apmārt koši dzeltenu apmali. Stublāja galā parasti ir 1-4 ziedi.",
        },
        {
            title: "Plīvurpuķe jeb ģipsene",
            image: "https://i.imgur.com/zqKob2gb.jpg",
            description: "To mēdz dēvēt par kaprīzo skaistuli no vecmāmiņas dārza. Izrādās tas ir cimperlīgs augs, ja vēlies to stādīt dārzā, bet kad tas uzzied, tad dārzs izskatās, kā balta, krāšņa kupena.",
        },
        {
            title: "Alstromērija",
            image: "https://i.imgur.com/X1Pefc5b.jpg",
            description: "No Dienvidamerikas kalniem, tā ir atceļojusi uz daudzām audzētavām, jo pateicoties ilgajam mūzām vāzē (pat 14 dienas) un, protams, skaistajiem zidiem, tā ir populāra puķe ziedu kompozīcijās. ",
        },
        {
            title: "Frēzija",
            image: "https://i.imgur.com/m9M0CpJb.jpg",
            native: "Dienvidāfrika",
            description: "Arī saukta par balerinām. Salda un maiga smarža un unikalitātes ziņā frēzijām līdzīgi ziedi nav atrodami. Tās izceļas ar to, ka ziedi aug paralēli zemei, spītējot gravitācijai."        },
            {
                title: "Pistāciju lapas",
                image: "https://i.imgur.com/3oYQgBmb.jpg",
                native: "Centrālāzija un Tuvie Austrumi",
                description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
            },
            {
                title: "Lizantes",
                image: "https://i.imgur.com/myXgGH6b.jpg",
                native: "Dienvidamerika",
                description: "Lizantes izceļas ar savu faktūru un vizuāli ļoti atsvaidzina ziedu kompozīciju. Šie ziedi vāzē var ilgt līdz pat 14 dienām.",
            },
            {
                title: "Savvaļas burkāns",
                image: "https://i.imgur.com/5rlAEIyb.jpg",
                description: "Un nē, parasti šo augu neēd, bet savvaļas burkāna pasuga dārza burkāns, gan ir mums labi zināma un garšota sakne.",
            },
            
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/ad7acba1-6238-4a79-b84e-d358d9642e9d/playlist.m3u8"
                        captions="/titles/007.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Ziedošā pļava ar daudz dažādiem ziediem</h2>

                            <p>Uz Lieldienām gribējām, ienest mājās zaļumu, krāsas un smaržu. Sakombinējām jums daudz un dažādus ziedus, dažādās krāsās un faktūrās, lai katru reizi paskatoties uz kompozīciju vāzē ieragat kaut ko jaunu. Lielākā daļa no ziediem, vēl izplauks un kompozīcija izmainīsies līdz nepazīšanai.</p>
                                
                            <p>Gandrīz visi ziedi šoreiz ir ļoti trausli, tāpēc mūsu ieteikums kompozīcijas veidošanas procesā ir sākt ar stingrākiem un stabilākiem ziediem, un uz beigām atstāt trauslos, kā piemēram ziedus, kas izskatās pēc “zvaniņiem”  jeb īstajā nosaukumā Fritilārijas uva-vulpis. Taisot video instrukciju netīšām paši nolauzām 2 ziedus. Trausli tie ir, bet cik skaisti un interesanti :)</p>

                            <p>Mēs kompozīcijā izvēlējāmies Alstromērijas atstāt garākas un izvietot tās kompozīcijas aizmugurē, lai veidojas pakāpienveida kompozīcija, kur priekšā ir īsāki ziedi, kas pakāpeniski kļūst garāki. Tomēr ziedi arī ļoti skaisti izskatīsies simetriskā aplī, kas skaisti iederēsies Lieldienās uz svētku galda. Ja vēlies simetriski, apaļu kompozīciju, tad būs nepieciešams ziedu kātus nogriezt vienādā garumā.</p>

                            <p>Mums tas atgādina Boho stilu, kas apvieno dabisko, dabā esošo, eksotisko un grezno vienotā un dabīgā kompozīcijā, ko vari pielāgot pēc saviem ieskatiem. Piemēram, neizmantojot niedres, vai noliekot oranžo Protea Nutan Soleil savā vāzē, kā akcentu citā telpā.</p>

                            <p>Ķeramies pie darba:</p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 2cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>
                                <li>Sākam ar zaļajām pistāciju lapām, ko izkārtojam pa vāzes perimetru</li>
                                <li>Turpinām ar savvaļas burkāna ziediem (Daucus carota dara) - teicām, ka būs pļava :) Ja, izvēlies pakāpienveida kompozīciju, tad 2 ziedus nogiez pāris cm īsākus un izkārto priekšpusē, pārējos ziedus atstāj garākus un izkārto vidū un aizmugurē</li>
                                <li>Baltos Skabioza ziedus izkārtojam pa perimetru kompozīcijā</li>
                                <li>Clematis jeb Mežvītenu ziedus kārtojam tā, lai ziedi ir pamanāmi, jo to krāsa piešķir kompozīcijai dziļumu un kontrastu</li>
                                <li>Turpinām ar maigi rozā Lizantēm, tās varat izkārtot pa perimetru, ar izplaukušākajiem ziediem, kompozīcjas centrā. Lai kompozīcija izskatītos harmoniska, iesakām atkārtot krāsu ritmu, piemēram, balta, violeta, rozā krāsa un tā pa apli (ņem vērā, ka daudzi ziedi kā Clematis un Lizantes vēl tikai izplauks), pat ja uzreiz ritms neredzas, pēc pāris dienām ziedi izplauks visā krāšņumā</li>
                                <li>Pasmaržo, cik skaisti smaržo frēzijas. Tās izkārtojam vāzes malās, jo ziedu “arkas” kompozīcijai piešķirs dimensijas un dziļumu</li>
                                <li>Ja izvēlējies pakāpienveida kompozīciju, tad oranžīgās alstromērijas izkārto kompozīcijas aizmugurē, kā augstāko punktu. Ja izvēlējies klasisko variantu, tad nogriez tās vienādā garumā ar pārējiem ziediem un izkārto vienādos attālumos vienu no otras</li>
                                <li>Visbeidzot izkārtojam zvaniņus, atceries, tie ir ļoti trausli un delikāti</li>
                                <li>Kā pēdējo liekam plīvurpuķi gar vāzes malām, kas vizuāli  “ierāmē” kompozīciju</li>
                            </ol>

                            <p>Atceries, mēģini ziedus izkārtot ritmā, kas atkārtojas, tad kompozīcija veidosies harmoniska un acīm patīkama.</p>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram!</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/1kGJjVVh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Īpaši labi ziedi stāvēs, ja ūdeni tiem mainīsi reizi 2 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
